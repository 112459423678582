module.exports = {
  mapBoxAccessToken:
    "pk.eyJ1Ijoic2FpamludGFvIiwiYSI6ImNrZXh2dHQyNjA1NGczMHBobnQ0bDM0ZGMifQ.dAO3yCa49vke7FVK7Saldw", //mapbox access token
  zoom: 12, //地图初始缩放级别
  center: [37.51, -237.883], //地图初始中心点 51.505, -0.09
  style: "mapbox/streets-v11", //地图样式
  maxZoom: 18, //最大缩放级别
  tileSize: 512, //瓦片数量 默认512×512像素
  attributionStr: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',//来源表述
};
