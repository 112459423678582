<template>
  <!-- 地图组件 -->
  <v-card>
    <v-card-title class="text-subtitle-1">{{$t('lang.home.stationLocation')}}</v-card-title>
    <v-card-text>
      <div id="map" style="min-height: 80vh"></div>
    </v-card-text>
  </v-card>
</template>
<script>
import L from "leaflet"; //leaflet地图
import mapCfg from "../config/map";
import axios from "axios"
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl;

let map;
export default {
  data() {
    return {};
  },
  mounted() {
    this.initMap();
    if(this.$root.authority != 'ROLE_FINANCIAL')this.getStationList()
  },
  methods: {
    //初始化地图
    initMap() {
      map = L.map("map", {
        attributionControl: true,
        zoomControl: false,
      }).setView(mapCfg.center, mapCfg.zoom);
      let mapOptions = {
        //attributionControl: false, //If true , an AttributionControl will be added to the map.
        attribution: mapCfg.attributionStr,
        maxZoom: mapCfg.maxZoom, //The maximum zoom level of the map (0-24).
        id: mapCfg.style, //	The ID of the style.
        tileSize: mapCfg.tileSize,
        zoomOffset: -1,
        accessToken: mapCfg.mapBoxAccessToken,
      };
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        mapOptions
      ).addTo(map);

      //缩放控件
      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(map);
      //比例尺
      L.control
        .scale({
          imperial: false,
          position: "bottomright",
        })
        .addTo(map);
    },
    //获得站列表
    getStationList() {
      axios.get(`${apiUrl}station/list`).then(res => {
        if(res.status == 200) {
          //console.log(res.data)
          this.showStationMarkers(res.data) 
        }
      }).catch(error => {
        console.log(error)
      })
    },
    //显示站点位置
    showStationMarkers(stationList) {
      for(let station of stationList) {
        let icon = L.icon({
          iconUrl: require("../../public/mark_b.png"),
          iconSize: [25, 30],
          //iconAnchor: [22, 94],
        });
        let marker =  L.marker([station.lat, station.lon], { icon: icon }).addTo(
          map
        );
        let popup = L.popup({ offset: [2, -2] })
        .setLatLng([station.lat, station.lon])
        .setContent(station.name);

        marker.on('click', function() {
          popup.openOn(map)
        })
      }
    }
  },
};
</script>
<style scope>
.leaflet-pane {
  z-index: 0;
}
.leaflet-bottom {
    z-index: 0;
}
</style>
