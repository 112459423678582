<template>
  <!-- 主页 -->
  <v-container>
    <!-- <v-row>
      <v-col cols="2" v-for="(statisticsItem,i) in statisticsList" :key="i">
        <v-card>
          <v-card-title class="subtitle-2">{{ statisticsItem.text }}</v-card-title>
          <v-card-subtitle class="text-caption text-sm-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 pb-0">{{ statisticsItem.value }}</v-card-subtitle>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-icon :size="$vuetify.breakpoint.smAndDown ? '18' : '38'" :color="statisticsItem.valueColor">{{ statisticsItem.icon }}</v-icon>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12">
        <main-map />
      </v-col>
      <!-- <v-col cols="6"></v-col> -->
    </v-row>
  </v-container>
</template>
<script>
import MainMap from "../components/MainMap.vue";

export default {
  components: {
    MainMap,
  },
  data() {
    return {
      statisticsList: [
        {
          text: this.$t('lang.home.count.totalUsers'),
          value: "1209",
          valueColor: "#1976d2",
          icon: "mdi-account-circle",
        },
        {
          text: this.$t('lang.home.count.totalProjects'),
          value: "60",
          valueColor: "rgb(244, 99, 99)",
          icon: "mdi-application",
        },
        {
          text: this.$t('lang.home.count.totalStations'),
          value: "180",
          valueColor: "green",
          icon: "mdi-devices",
        },
        {
          text: this.$t('lang.home.count.onlineStations'),
          value: "73",
          valueColor: "green",
          icon: "mdi-desktop-tower-monitor",
        }, //lan-pending
        {
          text: this.$t('lang.home.count.totalContracts'),
          value: "20",
          valueColor: "rgb(254, 148, 62)",
          icon: "mdi-file-document",
        },
        {
          text: this.$t('lang.home.count.signedContracts'),
          value: "15",
          valueColor: "rgb(254, 148, 62)",
          icon: "mdi-file-check",
        },
      ],
    };
  },
};
</script>